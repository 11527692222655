$mainColor: #6601A1;
$secondaryColor:rgb(242, 242, 242);
$disabledColor:#6c757d;
$fontSize:0.8rem;
@import 'toastr/toastr.scss';

a{
    color:$mainColor !important;
}
li.active{
    a{
        color:$disabledColor !important;
    }
}
.btn-outline-purple{
    color: $mainColor !important;
    border-color: $mainColor !important;
}
.badge-purple{
    background-color: $mainColor!important;
    color: white;
}
.btn-circle{
    border-radius: 30px!important;
    &::after{
        display: none !important;
    }
}
.btn-purple{
    background-color: $mainColor!important;
    color:white !important;
}
.footerLink{
    color:#bbb !important;
}
*.title{
    font-weight: 300;
    font-size: 2em;
}
*.subtitle{
    font-weight: 300;
    font-size: 1.4em;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

}
h4.title{
    font-weight: 300;
    font-size: 1.4em;
}
.zoom {
    & img:hover {
      opacity: 0;
    }
    img {
      transition: opacity .5s;
      display: block;
      width: 100%;
    }
    background-position: 50% 50%;
    background-size: 200%;
    position: relative;
    overflow: hidden;
    cursor: zoom-in;
}
.breadcrumb{background-color: transparent!important; padding-left:0!important;margin-bottom:0!important;}
.mainWrapper {
    color:$mainColor;
    font-size: $fontSize;
    li.separator > a{
        &::after{
            content: '|';
            padding:0 5px;
        }
    }
    .subscribeHome{
        background-color: $secondaryColor;
    }
}

.toast {
    background-color: #030303 !important;
}
.toast-success {
    background-color: #51a351 !important;
}
.toast-error {
    background-color: #bd362f !important;
}
.toast-info {
    background-color: #2f96b4 !important;
}
.toast-warning {
    background-color: #f89406 !important;
}
.card-custom{
    max-width:30%;
    min-width:30%!important;
    @media (max-width:768px){
        max-width:100% !important;
    }
}